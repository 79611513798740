<script setup lang="ts">
  import { ChevronRightIcon } from '@heroicons/vue/20/solid'

  const isOpen = ref(false)
  const runtimeConfig = useRuntimeConfig()
  const linkedin = runtimeConfig.public.linkedin
  const instagram = runtimeConfig.public.instagram
  const facebook = runtimeConfig.public.facebook

  const { t } = useI18n()
  const navigation = computed( () => [
    { name: t('header.home'), href: '/', chevron: false, news: false, soon: false },
    { name: t('header.yachts-for-sale'), href: '/yachts/sale', chevron: true, news: false, soon: false },
    { name: t('header.charter-a-yacht'), href: '/yachts/rent', chevron: true, news: false, soon: false },
    { name: t('header.berths'), href: '/berths', chevron: false, news: true, soon: false },
    { name: t('header.yacht-management'), href: '/yacht-management', chevron: true, news: false, soon: false },
    { name: t('header.build'), href: '', chevron: false, news: false, soon: true },
    { name: t('header.crew-management'), href: '/', chevron: false, news: false, soon: true },
    { name: t('header.news'), href: '/news', chevron: true, news: false, soon: false },
    { name: t('header.about-us'), href: '/about-us', chevron: true, news: false, soon: false },
    { name: t('header.contact'), href: '/contact', chevron: true, news: false, soon: false },
  ]);

</script>
<template>
  <nav class="bg-white shadow z-40">
    <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between w-full">
        <div class="flex-1 flex items-center">
          <UButton label="Open" @click="isOpen = true" class="rounded-none sm:w-12 sm:h-12 h-10 w-10">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </UButton>
          <USlideover side="left" v-model="isOpen">
            <UCard class="flex flex-col flex-1" :ui="{ body: { base: 'flex-1' }, ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
              <template #header>
                <UButton label="Close" @click="isOpen = false" class="rounded-none sm:w-12 sm:h-12 h-10 w-10">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                </UButton>
              </template>

              <div class="h-full px-4">
                <div class="flex flex-col items-center h-full space-y-4">
                  <NuxtLink v-for="item in navigation" @click="isOpen = false" :to="item.href ? localePath(item.href) : ''" :class="['text-2xl font-semibold flex items-center justify-between w-full px-4', item.soon ? 'text-gray-300 hover:bg-gray-200/25 p-1 transition ease-in-out rounded-sm cursor-no-drop' : 'text-ocean-950 hover:bg-ocean-950/25 p-1 transition ease-in-out rounded-sm']">
                    <h1>{{ item.name }}</h1>
                    <span class="flex items-center">
                      <ChevronRightIcon v-if="item.chevron && !item.soon" class="h-8 w-8 text-ocean-950" aria-hidden="true" />
                      <span v-if="item.soon" class="bg-gray-300 text-gray-600 text-sm py-0.5 font-bold px-2">Soon</span>
                      <span v-if="item.news" class="bg-ocean-950 text-white text-sm py-0.5 font-bold px-2">News</span>
                    </span>
                  </NuxtLink>
                </div>
              </div>

              <template #footer>
                <NuxtImg src="/assets/logo.webp" alt="logo" class="h-20 mx-auto" />
              </template>
            </UCard>
          </USlideover>
        </div>
        <div class="z-10 flex-2 mt-2 sm:mt-0">
          <div class="rounded-[50%] bg-white shadow-xl">
            <NuxtLink :to="localePath('/')">
              <NuxtImg src="/assets/logo.webp" alt="logo" class="sm:h-32 h-20 shrink-0 grow-0 sm:py-4 sm:px-16 py-1 pb-2 px-8"/>
            </NuxtLink>
          </div>
        </div>
        <div class="flex-1 flex items-center justify-end">
          <div class="mr-10 space-x-3 hidden sm:flex">
            <NuxtLink target="_blank" :to="facebook" class="h-7 w-7 rounded-full bg-ocean-950 hover:bg-ocean-1000 cursor-pointer">
              <svg id="Calque_2" data-name="Calque 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 397.35 397.35">
                <g id="objects">
                  <g>
                    <path class="fill-[#fff] stroke-0" d="M257.54,183.26l-3.31,26.58c-.56,4.44-4.32,7.78-8.78,7.78h-43.05v111.13c-4.54.41-9.14.62-13.79.62-10.4,0-20.55-1.04-30.36-3.02v-108.73h-33.11c-3.04,0-5.52-2.49-5.52-5.54v-33.26c0-3.05,2.48-5.54,5.52-5.54h33.11v-49.88c0-30.61,24.71-55.42,55.2-55.42h38.63c3.04,0,5.52,2.49,5.52,5.54v33.26c0,3.05-2.48,5.54-5.52,5.54h-27.6c-12.19,0-22.07,9.92-22.07,22.17v38.8h46.36c5.32,0,9.43,4.68,8.78,9.98Z"/>
                  </g>
                </g>
              </svg>
            </NuxtLink>
            <NuxtLink target="_blank" :to="instagram" class="h-7 w-7 rounded-full bg-ocean-950 hover:bg-ocean-1000 cursor-pointer">
                <svg id="Calque_2" data-name="Calque 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 397.35 397.35">
                  <g id="objects">
                    <g>
                      <path class="fill-[#fff] stroke-0" d="M252.58,94.46h-107.81c-27.78,0-50.31,22.53-50.31,50.31v107.81c0,27.78,22.53,50.31,50.31,50.31h107.81c27.78,0,50.31-22.53,50.31-50.31v-107.81c0-27.78-22.53-50.31-50.31-50.31ZM284.92,248.99c0,19.84-16.1,35.94-35.94,35.94h-100.62c-19.84,0-35.94-16.1-35.94-35.94v-100.62c0-19.84,16.1-35.94,35.94-35.94h100.62c19.84,0,35.94,16.1,35.94,35.94v100.62Z"/>
                      <path class="fill-[#fff] stroke-0" d="M198.78,144.77c-29.75,0-53.9,24.15-53.9,53.9s24.15,53.9,53.9,53.9,53.9-24.15,53.9-53.9-24.15-53.9-53.9-53.9ZM198.78,234.61c-19.8,0-35.94-16.14-35.94-35.94s16.14-35.94,35.94-35.94,35.94,16.14,35.94,35.94-16.14,35.94-35.94,35.94Z"/>
                      <circle class="fill-[#fff] stroke-0" cx="256.28" cy="141.18" r="10.78"/>
                    </g>
                  </g>
                </svg>
            </NuxtLink>
            <NuxtLink target="_blank" :to="linkedin" class="h-7 w-7 rounded-full bg-ocean-950 hover:bg-ocean-1000 cursor-pointer">
              <svg id="Calque_2" data-name="Calque 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 397.35 397.35">
                <g id="objects">
                  <g>
                    <g>
                      <circle class="fill-[#fff] stroke-0" cx="128.31" cy="119.97" r="25.59"/>
                      <rect class="fill-[#fff] stroke-0" x="106.98" y="162.62" width="42.65" height="127.94" rx="1.91" ry="1.91"/>
                      <path class="fill-[#fff] stroke-0" d="M294.64,209.53v72.5c0,4.69-3.84,8.53-8.53,8.53h-25.59c-4.69,0-8.53-3.84-8.53-8.53v-59.71c0-11.77-9.55-21.32-21.32-21.32s-21.32,9.55-21.32,21.32v59.71c0,4.69-3.84,8.53-8.53,8.53h-25.59c-4.69,0-8.53-3.84-8.53-8.53v-110.89c0-4.69,3.84-8.53,8.53-8.53h25.59c4.69,0,8.53,3.84,8.53,8.53v5.46c8.53-11.05,22.65-18.25,38.38-18.25,23.54,0,46.91,17.06,46.91,51.18Z"/>
                    </g>
                  </g>
                </g>
              </svg>
            </NuxtLink>
          </div>

          <div class="relative">
            <UtilsLangSwitcher />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
